export const onUpdateAssessment = /* GraphQL */ `
  subscription OnUpdateAssessment {
    onUpdateAssessment {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAnnouncement = /* GraphQL */ `
  subscription OnCreateAnnouncement(
    $filter: ModelSubscriptionAnnouncementFilterInput
  ) {
    onCreateAnnouncement(filter: $filter) {
      id
      assessmentId
      assessment {
        name
        status
        users {
          items {
            userId
          }
        }
        teams {
          items {
            team {
              members {
                items {
                  userId
                }
              }
            }
          }
        }
      }
      userId
      messages {
        type
        content
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateChallengeChat = /* GraphQL */ `
  subscription OnCreateChallengeChat(
    $filter: ModelSubscriptionChallengeChatFilterInput
  ) {
    onCreateChallengeChat(filter: $filter) {
      id
      assessmentId
      userId
      teamId
      challengeId
      messages {
        type
        content
      }
      assessment {
        name
      }
      challenge {
        name
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateChallengeChat = /* GraphQL */ `
  subscription OnUpdateChallengeChat(
    $filter: ModelSubscriptionChallengeChatFilterInput
  ) {
    onUpdateChallengeChat(filter: $filter) {
      id
      assessmentId
      userId
      teamId
      challengeId
      messages {
        type
        content
      }
      assessment {
        name
      }
      challenge {
        name
      }
      createdAt
      updatedAt
    }
  }
`;